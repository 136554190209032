import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Container,
  Heading,
  Button,
  Box,
  Text,
  Stack,
  Flex,
  useToast,
  VStack,
  Divider,
  HStack,
  Image,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBag } from '@fortawesome/free-solid-svg-icons';
import { useCart } from '../../contexts/CartContext';
import { useAuth } from '../../contexts/AuthContext';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import config from '../../config/config';

// Add this log to confirm we're using the production URL
console.log('Using production API:', config.api.baseURL);

// Add this for debugging
console.log('Current environment my boy:', {
    nodeEnv: process.env.NODE_ENV,
    apiUrl: config.api.baseURL,
    isProduction: process.env.NODE_ENV === 'production'
});

// At the very top of your file, after imports
console.log('🔥 Firebase Emulator Check:', {
    apiUrl: config.api.baseURL,
    isEmulator: process.env.NODE_ENV !== 'production',
    fullUrl: `${config.api.baseURL}/checkout/create-checkout-session`
});

// Add this debug function at the top of your component
const debugFetch = async (url, options) => {
  console.log('🔍 Debug Fetch Request:', {
    url,
    method: options.method,
    headers: options.headers,
    bodyPreview: options.body ? JSON.parse(options.body) : null
  });

  try {
    // Test endpoint first
    const testResponse = await fetch(`${config.api.baseURL}/checkout/test`);
    console.log('🧪 Test endpoint response:', {
      status: testResponse.status,
      ok: testResponse.ok,
      statusText: testResponse.statusText
    });

    // Make actual request
    const response = await fetch(url, options);
    console.log('📡 Response details:', {
      status: response.status,
      ok: response.ok,
      statusText: response.statusText,
      headers: Object.fromEntries(response.headers),
      url: response.url
    });

    if (!response.ok) {
      // Try to get error message from response
      try {
        const errorData = await response.json();
        console.error('🚫 Error response data:', errorData);
        throw new Error(errorData.error || `HTTP error! status: ${response.status}`);
      } catch (e) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    }

    return response;
  } catch (error) {
    console.error('🔥 Fetch error:', {
      message: error.message,
      stack: error.stack
    });
    throw error;
  }
};

const TestApiButton = () => {
  const [testStatus, setTestStatus] = useState('');
  
  const testApi = async () => {
    try {
      console.log('🧪 Testing API connection...');
      const testUrl = `${config.api.baseURL}/checkout/test`;
      console.log('Testing URL:', testUrl);
      
      const response = await fetch(testUrl);
      const data = await response.json();
      
      console.log('Test Response:', {
        status: response.status,
        ok: response.ok,
        data: data
      });
      
      setTestStatus(`API Status: ${response.ok ? '✅' : '❌'} ${response.status}`);
    } catch (error) {
      console.error('Test API Error:', error);
      setTestStatus(`Error: ${error.message}`);
    }
  };

  return (
    <Box mt={4}>
      <Text fontSize="sm" mb={2}>API URL: {config.api.baseURL}</Text>
      <Button size="sm" onClick={testApi}>Test API Connection</Button>
      {testStatus && <Text mt={2}>{testStatus}</Text>}
    </Box>
  );
};

const CartPage = () => {
  const { 
    cartItems, 
    removeFromCart, 
    cartTotal, 
    updateQuantity, 
    saveCartForCheckout 
  } = useCart();
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const toast = useToast();

  const handleCheckout = async () => {
    try {
      console.log('Making checkout request to:', `${config.api.baseURL}/checkout/create-checkout-session`);
      
      const response = await fetch(`${config.api.baseURL}/checkout/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          orderId: `order_${Date.now()}`,
          userId: currentUser?.uid,
          items: cartItems.map(item => ({
            title: item.title?.substring(0, 100), // Limit title length
            price: item.price,
            quantity: item.quantity,
            creatorImage: item.creatorImage
          }))
        })
      });

      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Checkout failed');
      }

      if (data.url) {
        window.location.href = data.url;
      } else {
        throw new Error('No checkout URL received');
      }
    } catch (error) {
      console.error('Checkout failed:', error);
      toast({
        title: "Checkout Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Container maxW="container.xl" py={8}>
      <Heading mb={6}>Shopping Cart 🛒</Heading>
      <Box bg="yellow.100" p={4} mb={4} borderRadius="md">
        <Text fontWeight="bold">Debug Mode Active</Text>
        <Text>Time: {new Date().toLocaleTimeString()}</Text>
      </Box>
      <TestApiButton />
      {cartItems.length === 0 ? (
        <Box textAlign="center" py={10}>
          <Text fontSize="xl" mb={4}>Your cart is empty</Text>
          <Button
            as={Link}
            to="/marketplace"
            colorScheme="blue"
            leftIcon={<FontAwesomeIcon icon={faShoppingBag} />}
          >
            Continue Shopping
          </Button>
        </Box>
      ) : (
        <Flex gap={8} direction={{ base: 'column', md: 'row' }}>
          {/* Left Side - Cart Items */}
          <Box flex="1">
            {cartItems.map((item) => (
              <Box key={item.offerId} p={4} borderWidth="1px" borderRadius="lg" mb={4}>
                <Flex gap={4}>
                  <Image
                    src={item.creatorImage}
                    alt={item.creatorName}
                    boxSize="100px"
                    objectFit="cover"
                    borderRadius="md"
                  />
                  <VStack align="stretch" spacing={2} flex="1">
                    <Text fontSize="xl" fontWeight="bold">{item.type}</Text>
                    <Text>by {item.creatorName}</Text>
                    <HStack justify="space-between" align="center">
                      <Text>Quantity:</Text>
                      <NumberInput 
                        value={item.quantity}
                        min={1}
                        max={10}
                        onChange={(value) => updateQuantity(item.offerId, parseInt(value))}
                        size="sm"
                        maxW="100px"
                      >
                        <NumberInputField />
                        <NumberInputStepper>
                          <NumberIncrementStepper />
                          <NumberDecrementStepper />
                        </NumberInputStepper>
                      </NumberInput>
                    </HStack>
                    <Text fontWeight="bold" fontSize="lg">${item.price.toFixed(2)}</Text>
                  </VStack>
                </Flex>
              </Box>
            ))}
          </Box>

          {/* Right Side - Order Summary */}
          <Box w={{ base: "100%", md: "400px" }}>
            <Box p={6} borderWidth="1px" borderRadius="lg" bg="white">
              <Text fontSize="xl" fontWeight="bold" mb={4}>Order Summary</Text>
              <VStack spacing={4} align="stretch">
                {cartItems.map((item) => (
                  <Flex key={item.offerId} justify="space-between">
                    <Text>{item.type}</Text>
                    <Text>${(item.price * item.quantity).toFixed(2)}</Text>
                  </Flex>
                ))}
                <Divider />
                <Flex justify="space-between" fontWeight="bold">
                  <Text>Total</Text>
                  <Text>${cartTotal.toFixed(2)}</Text>
                </Flex>
                <Button
                  colorScheme="green"
                  size="lg"
                  width="100%"
                  onClick={handleCheckout}
                  isLoading={isLoading}
                  loadingText="Processing..."
                >
                  Proceed to Checkout
                </Button>
              </VStack>
            </Box>
          </Box>
        </Flex>
      )}
    </Container>
  );
};

export default CartPage; 