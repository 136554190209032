import { getAuth } from 'firebase/auth';
import config from '../config/config';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebase';

// Single source of truth for API URL
export const API_BASE_URL = process.env.NODE_ENV === 'production'
    ? 'https://us-central1-hype-9b87b.cloudfunctions.net/api'
    : 'http://localhost:5001/api';

// Add debug logging
console.log('🔧 API Configuration:', {
    environment: process.env.NODE_ENV,
    baseUrl: API_BASE_URL,
    checkoutUrl: `${API_BASE_URL}/checkout/create-checkout-session`,
    testUrl: `${API_BASE_URL}/checkout/test`
});

// Test the checkout endpoint specifically
export const testCheckoutEndpoint = async () => {
    try {
        const url = `${API_BASE_URL}/checkout/test`;
        console.log('Testing checkout endpoint:', url);
        
        const response = await fetch(url);
        const data = await response.json();
        
        console.log('Checkout endpoint test:', {
            status: response.status,
            ok: response.ok,
            data: data
        });
        
        return data;
    } catch (error) {
        console.error('Checkout endpoint test failed:', error);
        return null;
    }
};

// Helper function to get auth token
async function getToken() {
    const auth = getAuth();
    const user = auth.currentUser;
    
    if (!user) {
        throw new Error('No authenticated user found');
    }
    
    return await user.getIdToken();
}

// Add checkout-specific functions
export const createCheckoutSession = async (orderData) => {
    try {
        const token = await getToken();
        const url = `${API_BASE_URL}/checkout/create-checkout-session`;
        
        console.log('Creating checkout session:', {
            url,
            orderId: orderData.orderId,
            itemCount: orderData.items?.length
        });

        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(orderData)
        });

        if (!response.ok) {
            const errorData = await response.json();
            throw new Error(errorData.error || 'Checkout session creation failed');
        }

        return response.json();
    } catch (error) {
        console.error('Error creating checkout session:', error);
        throw error;
    }
};

// Keep all your existing functions
export const registerCreator = async (userData) => {
    const response = await fetch(`${API_BASE_URL}/register/creator`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
    });

    if (!response.ok) {
        throw new Error('Registration failed');
    }

    return response.json();
};

export const registerBrand = async (userData) => {
    try {
        const response = await fetch(`${API_BASE_URL}/auth/register/brand`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(userData),
            credentials: 'include'
        });

        let data;
        try {
            data = await response.json();
        } catch (e) {
            console.error('Error parsing JSON response:', e);
            throw new Error('Invalid server response');
        }

        if (!response.ok) {
            throw new Error(data.message || data.error || 'Registration failed');
        }

        return data;
    } catch (error) {
        console.error('Error during registration:', error);
        throw error.message ? error : new Error('Network error during registration');
    }
};

export const login = async (email, password) => {
    const response = await fetch(`${API_BASE_URL}/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
    });

    if (!response.ok) {
        throw new Error('Login failed');
    }

    return response.json();
};

// Public endpoint for campaigns
export const fetchCampaigns = async (token) => {
  try {
    // Use direct URL construction for debugging
    const url = `${API_BASE_URL}/campaigns`;
    console.log('Fetching campaigns from:', url);
    
    const headers = {
      'Content-Type': 'application/json'
    };

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const response = await fetch(url, {
      method: 'GET',
      headers
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.campaigns || [];
  } catch (error) {
    console.error('Error fetching campaigns:', error);
    return [];
  }
};

export const getCampaignById = async (id) => {
    try {
        const response = await fetch(`${API_BASE_URL}/campaigns/${id}`);
        if (!response.ok) {
            throw new Error(`Failed to fetch campaign: ${response.status}`);
        }
        return response.json();
    } catch (error) {
        console.error('Error fetching campaign:', error);
        throw error;
    }
};

// Protected endpoints - require authentication
export const getBrandProfile = async () => {
    try {
        const response = await fetch(`${API_BASE_URL}/brand/profile`, {
            method: 'GET',
            credentials: 'include',
            headers: {
                'Accept': 'application/json'
            }
        });

        if (response.status === 431) {
            return { role: 'brand' };
        }

        if (!response.ok) {
            return null;
        }

        const data = await response.json();
        return data;
    } catch {
        return { role: 'brand' };
    }
};

export const fetchSelectedCampaigns = async () => {
    try {
        const token = await getAuth().currentUser?.getIdToken();

        const response = await fetch(`${API_BASE_URL}/campaigns/selected`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json'
            }
        });

        const contentType = response.headers.get('content-type');
        if (!contentType || !contentType.includes('application/json')) {
            throw new Error(`Expected JSON response but got ${contentType}`);
        }

        const data = await response.json();

        if (!response.ok) {
            throw new Error(`Failed to fetch selected campaigns: ${JSON.stringify(data)}`);
        }

        return data;
    } catch (error) {
        throw error;
    }
};

// Modified getCreators with simpler URL handling
export const getCreators = async (token) => {
  try {
    const url = `${API_BASE_URL}/creators`;
    console.log('Fetching creators from:', url);
    
    const headers = {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    };

    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    const response = await fetch(url, {
      method: 'GET',
      headers,
      mode: 'cors'
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data.creators || [];
  } catch (error) {
    console.error('Error in getCreators:', error);
    return [];
  }
} 