const config = {
  development: {
    api: {
      baseURL: 'http://localhost:5001/api',
    }
  },
  production: {
    api: {
      baseURL: 'https://us-central1-hype-9b87b.cloudfunctions.net/api',
    }
  },
  
  // Firebase Configuration
  firebase: {
    // Your existing Firebase config
  },
  
  // Other configurations
  stripe: {
    mode: process.env.NODE_ENV === 'production' ? 'live' : 'test',
    secretKey: process.env.STRIPE_SECRET_KEY,
    webhookSecret: process.env.STRIPE_WEBHOOK_SECRET,
    publicKey: process.env.STRIPE_PUBLIC_KEY
  },

  app: {
    environment: process.env.NODE_ENV || 'development',
    isProduction: process.env.NODE_ENV === 'production',
    isEmulator: process.env.NODE_ENV === 'development',
    baseUrl: process.env.NODE_ENV === 'production' ? 'https://us-central1-hype-9b87b.cloudfunctions.net/api' : 'http://localhost:5001/api',
    appUrl: process.env.APP_URL || 'https://hype-9b87b.web.app'
  }
};

const environment = process.env.NODE_ENV || 'development';
export default config[environment]; 